<template>
  <div
    class="nim-modal-background"
    :class="{'show' : show, 'top' : top}"
  >
    <div
      class="nim-modal text-platform-text-color overflow-x-hidden"
      :class="[{'show' : show, 'wide' : wide, 'half': half, 'ultra-wide': ultraWide, 'simple' : simple, }, 'modal-' + mode]"
      :style="[ maxPixels ? {maxWidth: maxPixels + 'px', width: '100%'} : '']"
    >
      <div
        v-if="!simple"
        id="modal-top"
        class="nim-modal-title flex"
      >
        <h3 class="m-0 font-normal grow text-left">
          <slot name="title" />
        </h3>
        <div
          v-if="showCross"
          class="shrink my-auto"
        >
          <i
            class="fas fa-times cursor-pointer text-black"
            @click="closeFromCross"
          />
        </div>
      </div>
      <div
        class="leading-snug"
        :class="{'simple' : simple, 'nim-modal-body' : marginBody}"
      >
        <slot />
      </div>
      <div
        v-if="showFooter && !simple"
        class="nim-modal-footer flex items-center justify-center space-x-3"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    mode: {
      type: String,
      default: 'info'
    },
    wide: {
      type: Boolean,
      default: false
    },
    websiteModal: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    half: {
      type: Boolean,
      default: false
    },
    ultraWide: {
      type: Boolean,
      default: false
    },
    showCross: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: false
    },
    maxPixels: {
      type: String | Number,
      required: false
    },
    marginBody: {
      type: Boolean,
      required: false,
      default: true
    }

  },
  created () {
    let vm = this
    document.addEventListener('click', function (event) {
      if (vm.show &&
        event.target &&
        typeof event.target.className == 'string' &&
        event.target.className !== '' &&
        event.target.className.includes('nim-modal-background')) {
        vm.$emit('clickoff-modal')
        vm.$emit('close-modal')
      }
    })
    document.addEventListener('keydown', function (event) {
      if (vm.show && event.key == 'Escape') {
        vm.$emit('escape-modal')
        vm.$emit('close-modal')
      }
    })
  },
  methods: {
    closeFromCross () {
      this.$emit('close-modal')
      this.$emit('clicked-cross')
    }
  }
}

</script>
